/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

/* External Imports */
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Link,
  List,
  ListItem,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { FaCaretDown, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';

/* Internal Imports */
/* Components */

/**
 *
 * @returns
 */
const Header = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [menuIndex, setMenuIndex] = useState(-1);
  const [toggle, setToggle] = useState(false);
  const [mtoggle, setMtoggle] = useState(false);

  const menuList = [
    {
      name: 'Kailash Manasarovar',
      url: '/sacred-walks/kailash-manasarovar-yatra',
    },
    { name: 'Himalayas', url: '/sacred-walks/himalayas-yatra' },
    { name: 'Kashi Krama', url: '/sacred-walks/kashi-krama-yatra' },
    { name: 'Southern Sojourn', url: '/sacred-walks/southern-sojourn-yatra' },
  ];
  const router = useRouter();
  const ref = useRef();
  const ref2 = useRef();

  useOutsideClick({
    ref: ref,
    handler: () => setToggle(false),
  });

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setMtoggle(false);
      setToggle(false);
    });
  }, []);

  useEffect(() => {
    if (mtoggle) {
      ref2.current.focus();
    }
  }, [mtoggle]);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    const heightToHideFrom = window.innerHeight;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  return (
    <Box
      height="100%"
      w="100%"
      //maxW={1300}
      mx="auto"
      h={{ md: isVisible ? '140px' : 0, base: 'auto' }}
      pos="fixed"
      top="0"
      left="0"
      zIndex="30"
    >
      <Box
        className="sticky-menu"
        h="100px"
        background="linear-gradient(180deg, rgb(0 0 0 / 72%) 18%, rgb(0 0 100 / 0%) 83%)"
        display={isVisible ? 'block' : 'none'}
      >
        <Flex className="desktop">
          <Box
            display="flex"
            flexDirection="row"
            mx="auto"
            pt="4px"
            pl="10px"
            px="16px"
            w="100%"
            justifyContent="space-between"
          >
            <Box>
              {/* <NextLink href="/sacred-walks" passHref legacyBehavior> */}
              <Link
                href="/sacred-walks"
                _focus={{ boxShadow: 'none' }}
              >
                <Image
                  mx="auto"
                  cursor="pointer"
                  userSelect="none"
                  maxW={{ base: '143.31px', lg: '129px' }}
                  height={{ lg: '100%', base: 'auto' }}
                  maxHeight="60px"
                  pt="20px"
                  src="/assets/images/IshaSacredWalks_Logo_EN_White12.png"
                //alt="IshaSacredWalks"
                />
              </Link>
              {/* </NextLink> */}
            </Box>
            <Box
              ref={ref}
              direction="row"
              mr="0px"
              display="flex"
              justifyItems="center"
              gridGap={{ md: '18px', lg: '28px' }}
              color="#FFF"
              fontFamily="FedraSansStd-Medium"
              fontSize='16px'
              lineHeight={{ md: '21.65px', base: '17.5px' }}
            >
              <Box>
                <Box
                  display="flex"
                  pt="30px"
                  transition="padding-right 0.3s"
                  pr={toggle ? '310px' : '0px'}
                >
                  <Flex flexDirection="row" border="none">
                    <Box pr="27px">
                    </Box>

                    {React.Children.toArray(
                      menuList.map((navItem, index) => {
                        return (
                          <>
                            <Box pr="27px" pos="relative"
                              fontSize={{ md: '16px', base: '14px' }}>
                              <Box
                                onMouseOver={() => {
                                  setMenuIndex(index);
                                }}
                                onMouseOut={() => {
                                  setMenuIndex(false);
                                }}
                                className="mainMenu"
                              >
                                {/* <NextLink
                                  href={navItem.url}
                                  passHref
                                  legacyBehavior
                                > */}
                                <Link
                                  href={navItem.url}

                                  _hover={{
                                    textDecoration: 'none',
                                    color: '#c85333',
                                  }}
                                  _focus={{ boxShadow: 'none' }}
                                >
                                  {navItem.name}
                                </Link>
                                {/* </NextLink> */}

                                <Box pos="absolute" pt="5px" w="max-content"
                                  fontSize={{ md: '14px', base: '14px' }}
                                >
                                  {menuIndex === index && (
                                    <List
                                      bg="black"
                                      p="10px 15px"
                                      className="list"
                                      borderRadius="5px"
                                      opacity="0.8"
                                      w="100%"
                                    >
                                      {/* <NextLink
                                        href={`${navItem.url}`}
                                        passHref
                                        legacyBehavior
                                      > */}
                                      <Link
                                        href={`${navItem.url}`}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="5px">
                                          About Yatra
                                        </ListItem>
                                      </Link>
                                      {/* </NextLink> */}

                                      {/* <NextLink
                                        href={`${navItem.url}/itinerary`}
                                        passHref
                                        legacyBehavior
                                      > */}
                                      <Link
                                        href={`${navItem.url}/itinerary`}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="15px">
                                          Itinerary
                                        </ListItem>
                                      </Link>
                                      {/* </NextLink> */}

                                      {/* <NextLink
                                        href={`${navItem.url}/dates-registration`}
                                        passHref
                                        legacyBehavior
                                      > */}
                                      <Link
                                        href={`${navItem.url}/dates-registration`}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="15px">
                                          Dates & Registrations
                                        </ListItem>
                                      </Link>
                                      {/* </NextLink> */}

                                      {/* <NextLink
                                        href={`${navItem.url}/program-guide`}
                                        passHref
                                        legacyBehavior
                                      > */}
                                      <Link
                                        href={`${navItem.url}/program-guide`}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="15px">
                                          Program Guide
                                        </ListItem>
                                      </Link>
                                      {/* </NextLink> */}

                                      {/* <NextLink
                                        href={`${navItem.url}/prerequisites`}
                                        passHref
                                        legacyBehavior
                                      > */}
                                      <Link
                                        href={`${navItem.url}/prerequisites`}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="15px">
                                          Prerequisities
                                        </ListItem>
                                      </Link>
                                      {/* </NextLink> */}

                                      {/* <NextLink
                                        href={`${navItem.url}/conveniences-offered`}
                                        passHref
                                        legacyBehavior
                                      > */}
                                      <Link
                                        href={`${navItem.url}/conveniences-offered`}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="15px">
                                          Conveniences Offered
                                        </ListItem>
                                      </Link>
                                      {/* </NextLink> */}
                                      {/* 
                                      <NextLink
                                        href={`${navItem.url}/faqs`}
                                        passHref
                                        legacyBehavior
                                      > */}
                                      <Link
                                        href={`${navItem.url}/faqs`}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="15px" >
                                          FAQ
                                        </ListItem>
                                      </Link>
                                      {/* </NextLink> */}
                                      {/* 
                                      <NextLink
                                        href={`${navItem.url}/gallery`}
                                        passHref
                                        legacyBehavior
                                      > */}
                                      <Link
                                        href={`${navItem.url}/gallery`}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="15px" pb="5px">Gallery</ListItem>
                                      </Link>
                                      {/* </NextLink> */}
                                    </List>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </>
                        );
                      })
                    )}
                    <Box pr="27px">
                      <List>
                        <ListItem>
                          {/* <NextLink
                            href="/sacred-walks/enquire-now"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/enquire-now"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                            cursor="pointer"
                          >
                            Enquire Now
                          </Link>
                          {/* </NextLink> */}
                        </ListItem>
                      </List>
                    </Box>
                  </Flex>
                  <Flex gridColumnGap="27px">
                    {/* <NextLink
                      href="tel:+918144123123"
                      passHref
                      legacyBehavior> */}
                    <Link
                      href="tel:+918144123123"

                      _hover={{ textDecoration: 'none', color: '#c85333' }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      <FaPhoneAlt fontSize="18px" />
                    </Link>
                    {/* </NextLink> */}

                    {/* <NextLink
                      href="https://wa.me/918144123123"
                      passHref
                      legacyBehavior
                    > */}
                    <Link
                      target="_blank"
                      href="https://wa.me/918144123123"
                      _hover={{ textDecoration: 'none', color: '#c85333' }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      <FaWhatsapp fontSize="18px" />
                    </Link>
                    {/* </NextLink> */}

                    <Box>
                      <Box
                        onClick={() => setToggle(!toggle)}
                        transform={toggle ? 'rotate(-90deg)' : ''}
                        cursor="pointer"
                        mt="-4px"
                      >
                        <FaCaretDown fontSize="25px" />
                      </Box>

                      {toggle ? (
                        <Box
                          //   overflow="auto"
                          //  pos="sticky"

                          maxH="auto"
                          maxW="310"
                          minW="300px"
                          w="100%"
                          background="#000"
                          h="100vh"
                          color="#fff"
                          position="absolute"
                          top="0"
                          pr="0px"
                          right="0"
                          ml="32px"
                          // right='-5px'

                          fontFamily="FedraSansStd-Medium"
                        >
                          <Box alignItems="center" pt="30px">
                            <Box
                              w="126px"
                              h="126px"
                              textAlign="center"
                              ml="85px"
                              borderRadius="50%"
                              boderColor="#fff"
                              border="2px"
                            ></Box>
                            {/* <NextLink
                              href="/sacred-walks"
                              passHref
                              legacyBehavior
                            > */}
                            <Link
                              onClick={() => setToggle(false)}
                              href="/sacred-walks"

                              _hover={{
                                textDecoration: 'none',
                                color: '#c85333',
                              }}
                            >
                              <Image
                                w="106px"
                                h="49px"
                                alignItems="center"
                                ml="95px"
                                mt="-90px"
                                src="/assets/images/IshaSacredWalks_Logo_EN_White12.png"
                                alt=""
                              />
                            </Link>
                            {/* </NextLink> */}
                          </Box>
                          <Text textAlign="center" mt="60px">
                            A Journey of a life time
                          </Text>
                          <Text
                            borderBottom="2px"
                            mt="20px"
                            borderBottomColor="white"
                            mx="auto"
                            w="90%"
                          ></Text>
                          <Box
                            overflowY='auto'
                            h={{ '2xl': '70vh', xl: '70vh' }}
                          >

                            {/* <NextLink
                              href="/sacred-walks/"
                              passHref
                              legacyBehavior
                            > */}
                            <Link
                              onClick={() => setToggle(false)}
                              href="/sacred-walks/"
                              _hover={{
                                textDecoration: 'none',
                                color: '#c85333',
                              }}
                              _focus={{ boxShadow: 'none' }}
                            >
                              <Text
                                mt="15px"
                                pl="20px"
                                fontSize={{ md: '19px' }}
                                lineHeight={{ md: '28px' }}
                              >
                                Home
                              </Text>
                            </Link>
                            {/* </NextLink> */}
                            {/* <NextLink
                              href="/sacred-walks/about-us"
                              passHref
                              legacyBehavior
                            > */}
                            <Link
                              onClick={() => setToggle(false)}
                              href="/sacred-walks/about-us"

                              _hover={{
                                textDecoration: 'none',
                                color: '#c85333',
                              }}
                              _focus={{ boxShadow: 'none' }}
                            >
                              <Text
                                mt="25px"
                                pl="20px"
                                fontSize={{ md: '19px' }}
                                lineHeight={{ md: '28px' }}
                                border="none"
                              >
                                About Us
                              </Text>
                            </Link>
                            {/* </NextLink> */}
                            <Accordion
                              border="none"
                              allowToggle={true}
                              mb="25px"
                            >
                              <AccordionItem borderTop="none">
                                <Box as="h2">
                                  <AccordionButton
                                    className="test"
                                    border="none"
                                    boxShadow="none"
                                    m="20px 0px -5px -15px"
                                    _focus={{ boxShadow: 'none' }}
                                  >
                                    <Box
                                      as="span"
                                      flex="1"
                                      fontSize={{ md: '19px' }}
                                      pl="20px"
                                      lineHeight={{ md: '28px' }}
                                      textAlign="left"
                                      _hover={{ color: '#c85333' }}
                                      _active={{ color: '#c85333' }}
                                    >
                                      Kailash Manasarovar
                                    </Box>
                                    <FaCaretDown />
                                  </AccordionButton>
                                </Box>
                                <AccordionPanel
                                  background="#131313"
                                  pl="45px"
                                  mt="5px"
                                  _focus={{ boxShadow: 'none' }}
                                >
                                  <List fontSize={{ md: '16px' }} pl="10px">
                                    {/* <NextLink
                                      href="/sacred-walks/kailash-manasarovar-yatra"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      href="/sacred-walks/kailash-manasarovar-yatra"
                                      onClick={() => setToggle(false)}
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      <ListItem pt="5px">
                                        About Yatra
                                      </ListItem>
                                    </Link>
                                    {/* </NextLink> */}

                                    {/* <NextLink
                                      href="/sacred-walks/kailash-manasarovar-yatra/itinerary"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      href="/sacred-walks/kailash-manasarovar-yatra/itinerary"
                                      onClick={() => setToggle(false)}
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        Itinerary
                                      </ListItem>
                                    </Link>
                                    {/* </NextLink> */}

                                    {/* <NextLink
                                      href="/sacred-walks/kailash-manasarovar-yatra/dates-registration"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      onClick={() => setToggle(false)}
                                      href="/sacred-walks/kailash-manasarovar-yatra/dates-registration"
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        Dates & registrations
                                      </ListItem>
                                    </Link>
                                    {/* </NextLink> */}

                                    {/* 
                                    <NextLink
                                      href="/sacred-walks/kailash-manasarovar-yatra/program-guide"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      onClick={() => setToggle(false)}
                                      href="/sacred-walks/kailash-manasarovar-yatra/program-guide"
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        Program Guide
                                      </ListItem>
                                    </Link>
                                    {/* </NextLink> */}
                                    {/* <NextLink
                                      href="/sacred-walks/kailash-manasarovar-yatra/prerequisites"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      onClick={() => setToggle(false)}
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      href="/sacred-walks/kailash-manasarovar-yatra/prerequisites"
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        Prerequisities
                                      </ListItem>
                                    </Link>
                                    {/* </NextLink>

                                    <NextLink
                                      href="/sacred-walks/kailash-manasarovar-yatra/conveniences-offered"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      href="/sacred-walks/kailash-manasarovar-yatra/conveniences-offered"
                                      onClick={() => setToggle(false)}
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      _focus={{ boxShadow: 'none' }}
                                    ><ListItem
                                      pt="10px"
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                        Conveniences Offered

                                      </ListItem>
                                    </Link>
                                    {/* </NextLink>

                                    <NextLink
                                      href="/sacred-walks/kailash-manasarovar-yatra/faqs"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      href="/sacred-walks/kailash-manasarovar-yatra/faqs"
                                      onClick={() => setToggle(false)}
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        FAQ
                                      </ListItem>
                                    </Link>
                                    {/* </NextLink>

                                    <NextLink
                                      href="/sacred-walks/kailash-manasarovar-yatra/gallery"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      href="/sacred-walks/kailash-manasarovar-yatra/gallery"
                                      onClick={() => setToggle(false)}
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        Gallery
                                      </ListItem>
                                    </Link>
                                    {/* </NextLink> */}
                                  </List>
                                </AccordionPanel>
                              </AccordionItem>

                              <AccordionItem border="none">
                                <Box as="h2">
                                  <AccordionButton
                                    className="test"
                                    m="20px 0px -5px -15px"
                                    border="none"
                                    boxShadow="none"
                                    _focus={{ boxShadow: 'none' }}
                                  >
                                    <Box
                                      as="span"
                                      flex="1"
                                      fontSize={{ md: '19px' }}
                                      pl="20px"
                                      lineHeight={{ md: '28px' }}
                                      textAlign="left"
                                      _hover={{ color: '#c85333' }}
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      Himalayas
                                    </Box>
                                    <FaCaretDown />
                                  </AccordionButton>
                                </Box>
                                <AccordionPanel
                                  background="#131313"
                                  pl="45px"
                                  mt="5px"
                                >
                                  <List fontSize={{ md: '16px' }} pl="10px">

                                    <NextLink
                                      href="/sacred-walks/himalayas-yatra"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="5px">
                                          About Yatra
                                        </ListItem>
                                      </Link>
                                    </NextLink>
                                    <NextLink
                                      href="/sacred-walks/himalayas-yatra/itinerary"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Itinerary
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/himalayas-yatra/dates-registration"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Dates & registrations
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/himalayas-yatra/program-guide"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      > <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                          Program Guide
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/himalayas-yatra/prerequisites"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Prerequisities
                                        </ListItem>
                                      </Link>
                                    </NextLink>
                                    <NextLink
                                      href="/sacred-walks/himalayas-yatra/conveniences-offered"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Conveniences Offered
                                        </ListItem>
                                      </Link>
                                    </NextLink>
                                    <NextLink
                                      href="/sacred-walks/himalayas-yatra/faqs"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          FAQ
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/himalayas-yatra/gallery"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      > <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                          Gallery
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                  </List>
                                </AccordionPanel>
                              </AccordionItem>

                              <AccordionItem
                                border="none"
                                _focus={{ border: 'none' }}
                              >
                                <Box as="h2">
                                  <AccordionButton
                                    className="test"
                                    m="20px 0px -5px -15px"
                                    _focus={{ boxShadow: 'none' }}
                                  >
                                    <Box
                                      as="span"
                                      flex="1"
                                      fontSize={{ md: '19px' }}
                                      pl="20px"
                                      lineHeight={{ md: '28px' }}
                                      textAlign="left"
                                      _hover={{ color: '#c85333' }}
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      Kashi Krama
                                    </Box>
                                    <FaCaretDown />
                                  </AccordionButton>
                                </Box>
                                <AccordionPanel
                                  background="#131313"
                                  pl="45px"
                                  mt="5px"
                                  _focus={{ boxShadow: 'none' }}
                                >
                                  <List fontSize={{ md: '16px' }} pl="10px">

                                    <NextLink
                                      href="/sacred-walks/kashi-krama-yatra"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="5px">
                                          About Yatra
                                        </ListItem>
                                      </Link>
                                    </NextLink>
                                    <NextLink
                                      href="/sacred-walks/kashi-krama-yatra/itinerary"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Itinerary
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/kashi-krama-yatra/dates-registration"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Dates & registrations

                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/kashi-krama-yatra/program-guide"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Program Guide
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/kashi-krama-yatra/prerequisites"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Prerequisities
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/kashi-krama-yatra/conveniences-offered"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Conveniences Offered
                                        </ListItem>
                                      </Link>
                                    </NextLink>


                                    <NextLink
                                      href="/sacred-walks/kashi-krama-yatra/faqs"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          FAQ
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/kashi-krama-yatra/gallery"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Gallery
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                  </List>
                                </AccordionPanel>
                              </AccordionItem>

                              <AccordionItem border="none">
                                <Box as="h2">
                                  <AccordionButton
                                    className="test"
                                    m="20px 0px -5px -15px"
                                    border="none"
                                    boxShadow="none"
                                    _focus={{ boxShadow: 'none' }}
                                  >
                                    <Box
                                      as="span"
                                      flex="2"
                                      fontSize={{ md: '19px' }}
                                      pl="20px"
                                      lineHeight={{ md: '28px' }}
                                      textAlign="left"
                                      _hover={{ color: '#c85333' }}
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      Southern Sojourn
                                    </Box>
                                    <FaCaretDown />
                                  </AccordionButton>
                                </Box>
                                <AccordionPanel
                                  background="#131313"
                                  pl="45px"
                                  mt="5px"
                                >
                                  <List fontSize={{ md: '16px' }} pl="10px">

                                    <NextLink
                                      href="/sacred-walks/southern-sojourn-yatra"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem pt="5px">
                                          About Yatra
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/southern-sojourn-yatra/itinerary"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Itinerary
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/southern-sojourn-yatra/dates-registration"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >

                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Dates & registrations
                                        </ListItem>
                                      </Link>
                                    </NextLink>
                                    <NextLink
                                      href="/sacred-walks/southern-sojourn-yatra/program-guide"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >

                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Program Guide
                                        </ListItem>
                                      </Link>
                                    </NextLink>

                                    <NextLink
                                      href="/sacred-walks/southern-sojourn-yatra/prerequisites"
                                      passHref

                                    // legacyBehavior

                                    >
                                      <Link
                                        onClick={() => setToggle(false)}
                                        _hover={{
                                          textDecoration: 'none',
                                          color: '#c85333',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        <ListItem
                                          pt="10px"
                                          _focus={{ boxShadow: 'none' }}
                                        >
                                          Prerequisities
                                        </ListItem>
                                      </Link>
                                    </NextLink>
                                    {/* <NextLink
                                      href="/sacred-walks/southern-sojourn-yatra/conveniences-offered"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      href="/sacred-walks/southern-sojourn-yatra/conveniences-offered"
                                      onClick={() => setToggle(false)}
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      _focus={{ boxShadow: 'none' }}
                                    >

                                      <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        Conveniences Offered
                                      </ListItem>
                                    </Link>
                                    {/* </NextLink> */}
                                    {/* <NextLink
                                      href="/sacred-walks/southern-sojourn-yatra/faqs"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      onClick={() => setToggle(false)}
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      href="/sacred-walks/southern-sojourn-yatra/faqs"
                                      _focus={{ boxShadow: 'none' }}
                                    >

                                      <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        FAQ
                                      </ListItem>
                                    </Link>
                                    {/* </NextLink> */}
                                    {/* <NextLink
                                      href="/sacred-walks/southern-sojourn-yatra/gallery"
                                      passHref
                                      legacyBehavior
                                    > */}
                                    <Link
                                      href="/sacred-walks/southern-sojourn-yatra/gallery"
                                      onClick={() => setToggle(false)}
                                      _hover={{
                                        textDecoration: 'none',
                                        color: '#c85333',
                                      }}
                                      _focus={{ boxShadow: 'none' }}
                                    >
                                      <ListItem
                                        pt="10px"
                                        _focus={{ boxShadow: 'none' }}
                                      >
                                        Gallery
                                      </ListItem>
                                    </Link>
                                    {/* </NextLink> */}
                                  </List>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>

                            {/* <NextLink
                              href="/sacred-walks/enquire-now"
                              passHref
                              legacyBehavior
                            > */}
                            <Link
                              href="/sacred-walks/enquire-now"

                              onClick={() => setToggle(false)}
                              _hover={{
                                textDecoration: 'none',
                                color: '#c85333',
                              }}
                              _focus={{ boxShadow: 'none' }}
                            >
                              <Text
                                mt="25px"
                                pl="20px"
                                fontSize={{ md: '19px' }}
                                lineHeight={{ md: '28px' }}
                                _focus={{ boxShadow: 'none' }}
                              >
                                Enquire Now
                              </Text>
                            </Link>
                            {/* </NextLink> */}
                            {/* 
                            <NextLink
                              onClick={() => setToggle(false)}
                              href="/sacred-walks/register-now"
                              passHref
                              legacyBehavior
                            > */}
                            <Link
                              onClick={() => setToggle(false)} // this was not in link this was in next link before
                              href="/sacred-walks/register-now"
                              _hover={{
                                textDecoration: 'none',
                                color: '#c85333',
                              }}
                              _focus={{ boxShadow: 'none' }}
                            >
                              <Text
                                mt="25px"
                                pl="20px"
                                fontSize={{ md: '19px' }}
                                lineHeight={{ md: '28px' }}
                                _focus={{ boxShadow: 'none' }}
                              >
                                Register
                              </Text>
                            </Link>
                            {/* </NextLink> */}

                            {/* <NextLink
                              href="/sacred-walks/contact-us"
                              passHref
                              legacyBehavior
                            > */}
                            <Link
                              onClick={() => setToggle(false)}
                              _hover={{
                                textDecoration: 'none',
                                color: '#c85333',
                              }}
                              href="/sacred-walks/contact-us"

                              _focus={{ boxShadow: 'none' }}
                            >
                              <Text
                                mt="25px"
                                pl="20px"
                                fontSize={{ md: '19px' }}
                                lineHeight={{ md: '28px' }}
                                _focus={{ boxShadow: 'none' }}
                              >
                                Contact Us
                              </Text>
                            </Link>
                            {/* </NextLink> */}
                          </Box>
                        </Box>
                      ) : (
                        ''
                      )}
                      <Box></Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>

        <Flex
          pt={{ base: '10px', md: '20px' }}
          className="mobile"

        >
          <Box
            display="flex"
            maxW={1200}
            flexDirection="row"
            mx="auto"
            w="95%"
            h="auto"
            justifyContent="space-between"
          >
            <Box mt="10px" h="auto" w="30%">
              {/* <NextLink href="/sacred-walks" passHref legacyBehavior> */}
              <Link
                _focus={{ boxShadow: 'none' }}
                href="/sacred-walks"
              >
                <Image
                  mx="10px"
                  cursor="pointer"
                  position="fixed"
                  userSelect="none"
                  maxW={{ base: '110px', md: '120px', lg: '160px' }}
                  height={{ lg: 'auto', base: 'auto' }}
                  //pt="5px"
                  src="/assets/images/IshaSacredWalks_Logo_EN_White12.png"
                // alt="IshaSacredWalks"
                />
              </Link>
              {/* </NextLink> */}
            </Box>

            <Box h="auto" w="70%" pt="20px">
              {!mtoggle ? (
                <Box
                  ml={{
                    xl: '95%',
                    lg: '95%',
                    md: '90%',
                    base: '87%',
                    sm: '90%',
                  }}
                >
                  <HamburgerIcon
                    width={{ base: '20px', md: '20px', xl: '25px' }}
                    height={{ base: '20px', md: '20px', xl: '25px' }}
                    //mt="10px"
                    color="#fff"
                    onClick={() => setMtoggle(true)}
                  />
                </Box>
              ) : (
                <Box
                  ml={{
                    xl: '95%',
                    lg: '95%',
                    md: '92%',
                    base: '87%',
                    sm: '90%',
                  }}
                  w="100%"
                >
                  <CloseIcon
                    //  class="closeIcon"
                    width={{ base: '15px', md: '18px', xl: '25px' }}
                    height={{ base: '15px', md: '18px', xl: '25px' }}
                    onClick={() => {
                      setMtoggle(false);
                    }}
                    color="#fff"
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box w="100%">
            {mtoggle && (
              <Box
                ref={ref2}
                tabIndex={0}
                maxW="100%"
                minW="300px"
                w="767px"
                background="rgba(0,0,0,0.90)!important"
                h="100vh"
                maxH='auto'
                color="#fff"
                position="absolute"
                //   maxH="auto"
                top={{ base: '95px', md: '95px', lg: '105px' }}
                right="0"
                opacity="1.0"
                transition="right 1.3s"
                fontFamily="FedraSansStd-Medium"
              >
                <Text
                  borderBottom="4px"
                  mt="20px"
                  borderBottomColor="white"
                  mx="auto"
                  w="90%"
                ></Text>
                <Box
                  w='100%'
                  overflowY='auto'
                  h={{ base: '80vh', md: '75vh', lg: '90vh' }}
                  top='0'
                  bottom='0'>

                  {/* <NextLink href="/sacred-walks/" passHref legacyBehavior> */}
                  <Link
                    href="/sacred-walks/"
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    _focus={{ boxShadow: 'none' }}
                  ><Text
                    mt="15px"
                    pl="20px"
                    fontSize={{ md: '19px' }}
                    lineHeight={{ md: '28px' }}
                  >
                      Home
                    </Text>
                  </Link>
                  {/* </NextLink> */}

                  {/* <NextLink
                    href="/sacred-walks/about-us"
                    passHref
                    legacyBehavior
                  > */}
                  <Link
                    href="/sacred-walks/about-us"

                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    _focus={{ boxShadow: 'none', backgroundColor: 'none' }}
                  ><Text
                    mt="25px"
                    pl="20px"
                    fontSize={{ md: '19px' }}
                    lineHeight={{ md: '28px' }}
                    border="none"
                  >
                      About Us

                    </Text>
                  </Link>
                  {/* </NextLink> */}
                  <Accordion border="none" allowToggle={true} mb="25px">
                    <AccordionItem borderTop="none">
                      <Box as="h2">
                        <AccordionButton
                          className="test"
                          border="none"
                          boxShadow="none"
                          m="20px 0px -5px -15px"
                          _focus={{ boxShadow: 'none' }}
                        >
                          <Box
                            as="span"
                            flex="1"
                            fontSize={{ md: '19px' }}
                            pl="20px"
                            lineHeight={{ md: '28px' }}
                            textAlign="left"
                            _hover={{ color: '#c85333' }}
                            _active={{ color: '#c85333' }}
                          >
                            Kailash Manasarovar
                          </Box>
                          <FaCaretDown />
                        </AccordionButton>
                      </Box>
                      <AccordionPanel
                        background="#131313"
                        pl="45px"
                        mt="5px"
                        _focus={{ boxShadow: 'none' }}
                      >
                        <List fontSize={{ md: '16px' }} pl="10px">

                          {/* <NextLink
                            href="/sacred-walks/kailash-manasarovar-yatra"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kailash-manasarovar-yatra"

                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="5px">
                              About Yatra
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}
                          {/* <NextLink
                            href="/sacred-walks/kailash-manasarovar-yatra/itinerary"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kailash-manasarovar-yatra/itinerary"

                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>

                              Itinerary
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}

                          {/* <NextLink
                            href="/sacred-walks/kailash-manasarovar-yatra/dates-registration"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            href="/sacred-walks/kailash-manasarovar-yatra/dates-registration"

                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Dates & registrations
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}

                          {/* <NextLink
                            href="/sacred-walks/kailash-manasarovar-yatra/program-guide"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                            href="/sacred-walks/kailash-manasarovar-yatra/program-guide"
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Program Guide
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}

                          {/* <NextLink
                            href="/sacred-walks/kailash-manasarovar-yatra/prerequisites"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kailash-manasarovar-yatra/prerequisites"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Prerequisities
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}
                          {/* 
                          <NextLink
                            href="/sacred-walks/kailash-manasarovar-yatra/conveniences-offered"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kailash-manasarovar-yatra/conveniences-offered"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Conveniences Offered
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}

                          {/* <NextLink
                            href="/sacred-walks/kailash-manasarovar-yatra/faqs"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kailash-manasarovar-yatra/faqs"

                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              FAQ
                            </ListItem>
                          </Link>
                          {/* </NextLink>
                          <NextLink
                            href="/sacred-walks/kailash-manasarovar-yatra/gallery"
                            passHref
                            legacyBehavior
                          >  */}
                          <Link
                            href="/sacred-walks/kailash-manasarovar-yatra/gallery"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Gallery
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}
                        </List>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem border="none">
                      <Box as="h2">
                        <AccordionButton
                          className="test"
                          m="20px 0px -5px -15px"
                          border="none"
                          boxShadow="none"
                          _focus={{ boxShadow: 'none' }}
                        >
                          <Box
                            as="span"
                            flex="1"
                            fontSize={{ md: '19px' }}
                            pl="20px"
                            lineHeight={{ md: '28px' }}
                            textAlign="left"
                            _hover={{ color: '#c85333' }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            Himalayas
                          </Box>
                          <FaCaretDown />
                        </AccordionButton>
                      </Box>
                      <AccordionPanel background="#131313" pl="45px" mt="5px">
                        <List fontSize={{ md: '16px' }} pl="10px">

                          {/* <NextLink
                            href="/sacred-walks/himalayas-yatra"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/himalayas-yatra"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="5px">
                              About Yatra
                            </ListItem>
                          </Link>
                          {/* </NextLink>
                          <NextLink
                            href="/sacred-walks/himalayas-yatra/itinerary"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/himalayas-yatra/itinerary"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>

                              Itinerary
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}
                          {/* <NextLink
                            href="/sacred-walks/himalayas-yatra/dates-registration"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/himalayas-yatra/dates-registration"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Dates & registrations
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}
                          {/* <NextLink
                            href="/sacred-walks/himalayas-yatra/program-guide"
                            passHref
                            legacyBehavior
                          >
                            <Link
                              _hover={{
                                textDecoration: 'none',
                                color: '#c85333',
                              }}
                              _focus={{ boxShadow: 'none' }}
                            >
                              <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                                Program Guide
                              </ListItem>
                            </Link>
                          </NextLink>
                          <NextLink
                            href="/sacred-walks/himalayas-yatra/prerequisites"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/himalayas-yatra/prerequisites"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          > <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Prerequisities
                            </ListItem>
                          </Link>
                          {/* </NextLink>
                          <NextLink
                            href="/sacred-walks/himalayas-yatra/conveniences-offered"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/himalayas-yatra/conveniences-offered"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Conveniences Offered
                            </ListItem>
                          </Link>
                          {/* </NextLink>

                          <NextLink
                            href="/sacred-walks/himalayas-yatra/faqs"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/himalayas-yatra/faqs"

                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              FAQ
                            </ListItem>
                          </Link>
                          {/* </NextLink>

                          <NextLink
                            href="/sacred-walks/himalayas-yatra/gallery"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/himalayas-yatra/gallery"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Gallery
                            </ListItem>

                          </Link>
                          {/* </NextLink> */}

                        </List>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem border="none" _focus={{ border: 'none' }}>
                      <Box as="h2">
                        <AccordionButton
                          className="test"
                          m="20px 0px -5px -15px"
                          _focus={{ boxShadow: 'none' }}
                        >
                          <Box
                            as="span"
                            flex="1"
                            fontSize={{ md: '19px' }}
                            pl="20px"
                            lineHeight={{ md: '28px' }}
                            textAlign="left"
                            _hover={{ color: '#c85333' }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            Kashi Krama
                          </Box>
                          <FaCaretDown />
                        </AccordionButton>
                      </Box>
                      <AccordionPanel
                        background="#131313"
                        pl="45px"
                        mt="5px"
                        _focus={{ boxShadow: 'none' }}
                      >
                        <List fontSize={{ md: '16px' }} pl="10px">

                          {/* <NextLink
                            href="/sacred-walks/kashi-krama-yatra"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kashi-krama-yatra"

                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="5px">
                              About Yatra
                            </ListItem>
                          </Link>
                          {/* </NextLink>

                          <NextLink
                            passHref
                            href="/sacred-walks/kashi-krama-yatra/itinerary"
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kashi-krama-yatra/itinerary"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Itinerary
                            </ListItem>
                          </Link>
                          {/* </NextLink>

                          <NextLink
                            href="/sacred-walks/kashi-krama-yatra/dates-registration"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            href="/sacred-walks/kashi-krama-yatra/dates-registration"
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Dates & registrations
                            </ListItem>
                          </Link>
                          {/* </NextLink>

                          <NextLink
                            href="/sacred-walks/kashi-krama-yatra/program-guide"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kashi-krama-yatra/program-guide"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Program Guide
                            </ListItem>
                          </Link>
                          {/* </NextLink>

                          <NextLink
                            href="/sacred-walks/kashi-krama-yatra/prerequisites"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kashi-krama-yatra/prerequisites"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Prerequisities
                            </ListItem>
                          </Link>
                          {/* </NextLink>

                          <NextLink
                            href="/sacred-walks/kashi-krama-yatra/conveniences-offered"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kashi-krama-yatra/conveniences-offered"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Conveniences Offered
                            </ListItem>
                          </Link>
                          {/* </NextLink>

                          <NextLink
                            href="/sacred-walks/kashi-krama-yatra/faqs"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kashi-krama-yatra/faqs"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              FAQ
                            </ListItem>
                          </Link>
                          {/* </NextLink>  <NextLink
                            href="/sacred-walks/kashi-krama-yatra/gallery"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/kashi-krama-yatra/gallery"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Gallery
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}
                        </List>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem border="none">
                      <Box as="h2">
                        <AccordionButton
                          className="test"
                          m="20px 0px -5px -15px"
                          border="none"
                          boxShadow="none"
                          _focus={{ boxShadow: 'none' }}
                        >
                          <Box
                            as="span"
                            flex="2"
                            fontSize={{ md: '19px' }}
                            pl="20px"
                            lineHeight={{ md: '28px' }}
                            textAlign="left"
                            _hover={{ color: '#c85333' }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            Southern Sojourn
                          </Box>
                          <FaCaretDown />
                        </AccordionButton>
                      </Box>
                      <AccordionPanel background="#131313" pl="45px" mt="5px">
                        <List fontSize={{ md: '16px' }} pl="10px">

                          {/* <NextLink
                            href="/sacred-walks/southern-sojourn-yatra"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/southern-sojourn-yatra"

                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="5px">
                              About Yatra
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}
                          {/* <NextLink
                            href="/sacred-walks/southern-sojourn-yatra/itinerary"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/southern-sojourn-yatra/itinerary"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Itinerary
                            </ListItem>

                          </Link>
                          {/* </NextLink> */}

                          {/* <NextLink
                            href="/sacred-walks/southern-sojourn-yatra/dates-registration"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/southern-sojourn-yatra/dates-registration"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Dates & registrations
                            </ListItem>
                          </Link>
                          {/* </NextLink>

                          <NextLink
                            href="/sacred-walks/southern-sojourn-yatra/program-guide"
                            passHref
                            legacyBehavior
                          >
                            <Link
                              _hover={{
                                textDecoration: 'none',
                                color: '#c85333',
                              }}
                              _focus={{ boxShadow: 'none' }}
                            >
                              <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                                Program Guide
                              </ListItem>
                            </Link>
                          </NextLink>

                          <NextLink
                            href="/sacred-walks/southern-sojourn-yatra/prerequisites"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/southern-sojourn-yatra/prerequisites"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Prerequisities
                            </ListItem>
                          </Link>
                          {/* </NextLink>

                          <NextLink
                            href="/sacred-walks/southern-sojourn-yatra/conveniences-offered"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            href="/sacred-walks/southern-sojourn-yatra/conveniences-offered"
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Conveniences Offered
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}
                          {/* <NextLink
                            href="/sacred-walks/southern-sojourn-yatra/faqs"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/southern-sojourn-yatra/faqs"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              FAQ
                            </ListItem>
                          </Link>
                          {/* </NextLink>
                          <NextLink
                            href="/sacred-walks/southern-sojourn-yatra/gallery"
                            passHref
                            legacyBehavior
                          > */}
                          <Link
                            href="/sacred-walks/southern-sojourn-yatra/gallery"
                            _hover={{
                              textDecoration: 'none',
                              color: '#c85333',
                            }}
                            _focus={{ boxShadow: 'none' }}
                          >
                            <ListItem pt="10px" _focus={{ boxShadow: 'none' }}>
                              Gallery
                            </ListItem>
                          </Link>
                          {/* </NextLink> */}

                        </List>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>


                  {/* <NextLink
                    href="/sacred-walks/enquire-now"
                    passHref
                    legacyBehavior
                  > */}
                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    _focus={{ boxShadow: 'none' }}
                    href="/sacred-walks/enquire-now"
                  >
                    <Text
                      mt="25px"
                      pl="20px"
                      fontSize={{ md: '19px' }}
                      lineHeight={{ md: '28px' }}
                    >
                      Enquire Now
                    </Text>
                  </Link>
                  {/* </NextLink>

                  <NextLink
                    href="/sacred-walks/register-now"
                    passHref
                    legacyBehavior
                  > */}
                  <Link
                    href="/sacred-walks/register-now"
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    <Text
                      mt="25px"
                      pl="20px"
                      fontSize={{ md: '19px' }}
                      lineHeight={{ md: '28px' }}
                    >
                      Register
                    </Text>
                  </Link>
                  {/* </NextLink> */}

                  {/* <NextLink
                    href="/sacred-walks/contact-us"
                    passHref
                    legacyBehavior
                  > */}
                  <Link
                    href="/sacred-walks/contact-us"
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    <Text
                      mt="25px"
                      pl="20px"
                      fontSize={{ md: '19px' }}
                      lineHeight={{ md: '28px' }}
                    >
                      Contact Us
                    </Text>
                  </Link>
                  {/* </NextLink> */}
                </Box>
              </Box>
            )}
          </Box>
        </Flex>
        <Box></Box>
      </Box>
      <Box></Box>

      <style global jsx>
        {`
          @media only screen and (max-width: 1200px) {
            .desktop {
              display: none !important;
            }
            .mobile {
              display: block !important;
            }
          }
          @media only screen and (min-width: 1200px) {
            .desktop {
              display: block !important;
            }
            .mobile {
              display: none !important;
            }
          }
          }

          .list {
            display: none !important;
          }

          .list:hover {
            display: block !important;
            background-color: none !important;
          }

          .mainMenu:hover .list {
            display: block !important;
          }
          .downArrow {
            transition: all 200ms linear;
          }

          .sticky-menu {
            position: sticky;
            top: 0px;
            z-index: 100;
          }

          /* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey
  ; 
  border-radius: 10px;
}

          
        `}
      </style>
    </Box>
  );
};

export default Header;
