/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import Footer from '@sw/components/Navigation/Footer';
import Header from '@sw/components/Navigation/Header';

/**
 * Renders the Layout component.
 *
 * @param {object} children - Child Elements.
 * @returns {ReactElement} Layout component
 */
export default function SwLayout({ children }) {
  return (
    <Box
    >
      <Header />
      {children}
      <Footer />
    </Box>
  );
}
