/* Built In Imports */

/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Image,
  Link,
  List,
  ListItem,
  Text
} from '@chakra-ui/react';
import { BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const footer = () => {
  return (
    <Box
      as="footer"
      w="100%"
      bgImage={{ md: "https://static.sadhguru.org/d/46272/1698469796-swfooterdesktop.jpg", base: "https://static.sadhguru.org/d/46272/1698469803-swfootermobile.jpg" }}
      // bg="url('https://static.sadhguru.org/d/46272/1693673921-new-project.jpg')"
      bgSize="cover"
      bgPos="center"
      bgRepeat="no-repeat"
      px={{ base: '20px', md: '20px', lg: '0' }}
    >
      <Flex
        py={{ base: "10px", md: "20px" }}
        color="black"
        flexDir={{ base: 'column', xl: 'row' }}
        maxW={{ md: "720px", lg: "960px", xl: "1170px" }}
        mx="auto"
      >
        <Flex
          flexDir="column"
          justifyContent={{ base: "flex-start", lg: "space-between" }}
          w="full"
          gridGap={{ base: '0px', md: "20px", xl: 0 }}
          width="80%"
        >
          <Box display="flex" justifyContent="flex-start">
            {/* <NextLink href="/sacred-walks" passHref legacyBehavior> */}
            <Link
              href="/sacred-walks"
            >
              <Image
                mx="auto"
                cursor="pointer"
                userSelect="none"
                maxW={{ base: '110px', md: '120px', lg: '129px' }}
                height={{ lg: 'auto', base: 'auto' }}
                // pt="15px"
                src="https://static.sadhguru.org/d/46272/1698488941-logoblack.png"
                alt="IshaSacredWalks"
              />
            </Link>
            {/* </NextLink> */}
          </Box>

          <Text
            pt={{ base: '20px', md: '0px' }}
            fontSize={{ base: "14px", md: '14px', xl: '15px' }}
            fontFamily="FedraSansStd-book"
            maxW={{ base: "100%", md: "80%", lg: "60%" }}
          >
            Isha Sacred Walks are journeys to places of divine connection, where
            the veil between the physical and spiritual is thin. Such sacred
            spaces revitalize and energize us, and give us an experience of our
            inner nature.{' '}
          </Text>

          <Flex
            gridColumnGap="27px"
            display={{ base: 'none', md: 'flex' }}
            fontSize="22px"
          >
            <Link
              target="_blank"
              cursor="pointer"
              outline="0"
              _hover={{
                textDecoration: 'none',
                color: '#c85333',
              }}
              href="https://twitter.com/ishaSacredWalks"
            >
              <Icon
                w="35px"
                h="25px"
                d="block"
                viewBox="0 0 1668.56 1221.19"
                transform="translate(52.390088,-25.058597)"
                outline="0"
              >
                <path
                  id="path1009"
                  d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
		h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"
                />
              </Icon>
              {/* <BsTwitterX /> */}
            </Link>
            <Link
              target="_blank"
              _hover={{
                textDecoration: 'none',
                color: '#c85333',
              }}
              outline="0"
              marginLeft="-10px"
              href="https://www.facebook.com/IshaSacredWalks"
            >
              <FaFacebookF />
            </Link>
            <Link
              target="_blank"
              _hover={{
                textDecoration: 'none',
                color: '#c85333',
              }}
              outline="0"
              href="https://www.instagram.com/ishasacredwalks/"
            >
              <BsInstagram />
            </Link>
          </Flex>
        </Flex>

        <Flex
          pt={{ base: '2rem', md: "2rem", lg: '0' }}
          flexDir={{ base: 'column', md: 'row' }}
          gridGap={{ base: '0px', md: "60px", lg: "40px" }}
          width="full"
        >
          <Flex
            display={{ base: 'none', md: 'initial' }}
            flexDir="column"
            justifyContent={{ md: "space-between" }}
            width="full"
          >
            <Box
              as="h3"
              fontSize={{ base: "20px", lg: '22px' }}
              fontFamily="FedraSansStd-A-medium"
              mb="15px"
            >
              Our Offerings
            </Box>
            <List fontSize="15px" fontFamily="FedraSansStd-book">
              <ListItem>
                <Link href="/sacred-walks/kailash-manasarovar-yatra">
                  {' '}
                  Kailash Manasarovar{' '}
                </Link>
              </ListItem>

              <Link href="/sacred-walks/himalayas-yatra">
                <ListItem>Himalayas</ListItem>
              </Link>
              <Link
                _hover={{
                  textDecoration: 'none',
                  color: '#c85333',
                }}
                href="/sacred-walks/kashi-krama-yatra"
              >
                <ListItem>Kashi Krama</ListItem>
              </Link>
              <Link
                _hover={{
                  textDecoration: 'none',
                  color: '#c85333',
                }}
                href="/sacred-walks/southern-sojourn-yatra"
              >
                <ListItem>Southern Sojourn</ListItem>
              </Link>
            </List>
          </Flex>

          <Flex
            display={{ base: 'none', md: 'initial' }}
            flexDir="column"
            gridRowGap="55px"
            maxW="264px"
            fontFamily="FedraSansStd-book"
            width="full"
          >
            <Box
              as="h3"
              fontSize={{ base: "20px", lg: '22px' }}
              fontFamily="FedraSansStd-A-medium"
              mb="15px"
            >
              Quick Links
            </Box>
            <List fontSize="15px" fontFamily="FedraSansStd-book">
              <ListItem>
                {/* <NextLink
                  href="/sacred-walks/why-pilgrimage"
                  passHref
                  legacyBehavior
                > */}
                <Link
                  href="/sacred-walks/why-pilgrimage"
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}
                >
                  Why Pilgrimage
                </Link>
                {/* </NextLink> */}
              </ListItem>

              {/* <NextLink href="/sacred-walks/about-us" passHref legacyBehavior> */}
              <Link
                href="/sacred-walks/about-us"
                _hover={{
                  textDecoration: 'none',
                  color: '#c85333',
                }}
              >
                <ListItem>About Us</ListItem>
              </Link>
              {/* </NextLink> */}

              {/* <NextLink
                href="/sacred-walks/register-now"
                passHref
                legacyBehavior
              > */}
              <Link
                href="/sacred-walks/register-now"
                _hover={{
                  textDecoration: 'none',
                  color: '#c85333',
                }}
              >
                <ListItem>Register</ListItem>
              </Link>
              {/* </NextLink> */}
              {/* <NextLink href="/sacred-walks/contact-us" passHref legacyBehavior> */}
              <Link
                _hover={{
                  textDecoration: 'none',
                  color: '#c85333',
                }}
                href="/sacred-walks/contact-us"
              >
                <ListItem>Contact Us</ListItem>
              </Link>
              {/* </NextLink> */}
            </List>
          </Flex>

          <Accordion
            display={{ base: 'initial', md: 'none' }}
            allowToggle={true}
            mb="20px"
          >
            <AccordionItem>
              <h2>
                <AccordionButton borderTop="1px solid black" pl="0" _active={{ boxShadow: 'none', background: 'none' }} _focus={{ boxShadow: 'none', background: 'none' }}>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize="20px"
                    fontFamily="FedraSansStd-A-medium"
                  >
                    Our Offerings
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel lineHeight="30px" m="0px" p="0px">
                <List fontSize="16px" py="10px" fontFamily="FedraSansStd-book">
                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    href="/sacred-walks/kailash-manasarovar-yatra"
                  >
                    <ListItem>Kailash Manasarovar</ListItem>
                  </Link>
                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    href="/sacred-walks/himalayas-yatra"
                  >
                    <ListItem>Himalayas</ListItem>
                  </Link>
                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    href="/sacred-walks/kashi-krama-yatra"
                  >
                    <ListItem>Kashi Krama</ListItem>
                  </Link>
                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    href="/sacred-walks/southern-sojourn-yatra"
                  >
                    <ListItem>Southern Sojourn</ListItem>
                  </Link>
                </List>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem
              borderBottom="1px solid black">
              <h2>
                <AccordionButton
                  borderTop="1px solid black"
                  pl="0"
                  _active={{ boxShadow: 'none' }} _focus={{ boxShadow: 'none' }}
                >
                  <Box
                    as="span"
                    flex="1"
                    fontSize="20px"
                    fontFamily="FedraSansStd-A-medium"
                    textAlign="left"
                  >
                    Quick Links
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel lineHeight="30px" m="0px" p="0px">
                <List fontSize="16px" py="10px" fontFamily="FedraSansStd-book">
                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    href="/sacred-walks/why-pilgrimage"
                  >
                    <ListItem>Why Pilgrimage</ListItem>
                  </Link>
                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    href="/sacred-walks/about-us"
                  >
                    <ListItem>About Us</ListItem>
                  </Link>

                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    href="/sacred-walks/register-now"
                  >
                    <ListItem>Register</ListItem>
                  </Link>
                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#c85333',
                    }}
                    _active={{ color: '#c85333' }}
                    _focus={{ boxShadow: 'none' }}
                    href="/sacred-walks/contact-us"
                  >
                    <ListItem>Contact Us</ListItem>
                  </Link>
                </List>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Flex
            fontFamily="FedraSansStd-A-medium"
            flexDir="column"
            gridRowGap="25px"
            maxW="264px"
            w="full"
          >
            <Box as="h3" fontSize={{ base: "20px", lg: "22px" }}>
              Contact Us
            </Box>

            <Box fontFamily="FedraSansStd-book">
              <Box as="h5" mb="8px" fontSize="18px">
                INDIA
              </Box>
              <List fontSize="15px" mb="8px">
                <ListItem>+91 8144123123</ListItem>
                <Link
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}
                  href="mailto:india@sacredwalks.org"
                >
                  <ListItem>india@sacredwalks.org</ListItem>
                </Link>
              </List>
              <Box as="h5" mt="1rem" mb="8px" fontSize="18px">
                USA
              </Box>
              <List fontSize="15px">
                <ListItem>+1-612-562-9330</ListItem>
                <Link
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}
                  href="mailto:usa@sacredwalks.org"
                >
                  <ListItem>usa@sacredwalks.org</ListItem>
                </Link>
              </List>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Box
        borderBottom="0.5px solid black"
        mx='auto'
        py={{ base: "0.5rem", xl: 0 }}

        maxW={{ md: "720px", lg: "960px", xl: "1170px" }}
      ></Box>
      <Flex alignItems='center' justifyContent='center' as="p" textAlign="center" color="black" pt={{ base: '24px', md: "15px" }} pb="15px" fontSize={{ base: '12px', md: '13px' }}>
        © 2018 - 2023 Isha Sacred Walks. All Rights Reserved.
      </Flex>
      <style global jsx>
        {`
          svg:hover {
            fill: #c85333 !important;
          }

          a:hover {
            color: #c85333 !important;
          }
          *:focus {
            outline: none;
          }
        `}
      </style>
    </Box>
  );
};

export default footer;
